import axios from 'axios';
import fileSaver from 'file-saver';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import { batchActions } from 'redux-batched-actions';
import { quickHash } from '../util/mathHelpers';
import *  as types from '../constants/actionTypes';
import { startSpinner, setToken, addMessage, setCurrentVersion, stopSpinner } from './systemActions';
import * as messageTypes from '../constants/messageTypes';
import cachableNames from '../constants/cachableNames';

import {
  getDataSuccess,
  getDataFailed,
  getDataBatchSuccess,
  getDataBatchFailed,
  addDataSuccess,
  addDataFailed,
  editDataSuccess,
  editDataFailed,
  deleteDataSuccess,
  deleteDataFailed,
} from './dataActions';
import {
  getItemSuccess,
  getItemFailed,
  addItemSuccess,
  addItemFailed,
  editItemSuccess,
  editItemFailed,
  deleteItemSuccess,
  deleteItemFailed
} from './itemActions';
import { getFileSuccess, getFileFailed } from './fileActions';
import CancelTokens from './helpers/CancelTokens';

const {v4} = require('uuid');

// Ignore these statuses from Trace Logs
const ignoreResponseStatuses = [401, 422];

const getMessage = key => (messages, data) => {
  const message = messages ? messages[key] : null;
  return typeof message === 'function' ? message(data) : message;
};

const getSuccessMessage = getMessage('success');
const getFailedMessage = getMessage('failed');

// Log trace
// headers are used for authentication and to retrieve organization, facility and user
function logTrace(headers, key, method, trace, extra_data) {
  // let user_id, facility_id, organization_id = '';
  // if (headers) {
  //   const tracking_id = get(headers, 'x-mjf-tracking', '').split('-');
  //   organization_id = get(tracking_id, '0', '');
  //   facility_id = get(tracking_id, '1', '');
  //   user_id = get(tracking_id, '2', '');
  // }
  //
  // const data = {key, organization_id, facility_id, user_id, service: 'ui', method, trace, extra_data};
  // const options = {method: 'POST', headers, data, url: '/api/traces/log_trace'};
  //
  // axios(options);
}

export function startRequest (name, now) {
  return {type: types.SET_REQUEST_START, payload: now, name};
}

export function completeRequest (name, start, now) {
  return {type: types.COMPLETE_API_REQUEST, start, now, name};
}

export function getPaginationSuccess (pagination) {
  return {type: types.GET_PAGINATION_SUCCESS, pagination};
}

export function clearMeta () {
  return {type: types.CLEAR_META};
}

/**
 * Handles most cases of our ajax errors
 * @param data - error data
 * @param promise - resolve and rejects that belong to the current action
 * @param handler - instantiated handler
 * @param fixtures - other required functions/objects - dispatch and axios currently
 * @returns {boolean}
 */
export const defaultErrorHandler = (data, promise, handler, fixtures = {}) => {
  const {dispatch, stopSpinner, axios} = fixtures;
  const {resolve, reject} = promise;

  const stopSpinnerIfRequested = () => {
    if (stopSpinner) {
      dispatch(stopSpinner());
    }
  };

  const requestWasCancelled = (error) => {
    if (axios && axios.isCancel(error)) {
      resolve('Request canceled');
      return true;
    }
    return false;
  };

  // main
  stopSpinnerIfRequested();
  if (requestWasCancelled(data)) {
    return true;
  }
  reject(data);
  handler.setError(data)
    .showValidationErrors()
    .failed(reject, true);
  return true;
};

/**
 * Handles most of our success cases.
 * @param data - response
 * @param promise - reject and resolve
 * @param handler - instance of requestHandler
 * @param flags - currently just showWarnings
 * @param config - passed into primary action used here to control response in promise
 */
export const defaultSuccessHandler = (data, promise, handler, flags, config) => {
  const {resolve} = promise;
  const {showWarnings} = flags;

  handler.setResponse(data);

  if (resolve) {
    const resolveData = config && config.resolveRaw ? data : handler.response.data;
    resolve(resolveData);
  }

  if (showWarnings) {
    handler.showWarnings();
  }

  handler.success()
    .runNext();
};

/**
 * Core of most ajax calls
 * @param handle - instance of requestHandler
 * @param promise - resolve and reject functions
 * @param fixtures - currently dispatch, stopSpinner, axios, and getState
 * @param flags - current showWarnings though there could be others
 * @returns {*}
 */
export const defaultAjaxCall = (handle, promise, fixtures, flags) => {
  const {dispatch, getState, axios, payload, config} = fixtures;
  const {resolve, reject} = promise;
  handle.redux(dispatch, getState).setRequestStart();

  const methodRequiresPayload = (method) => {
    const methodsWithPayloads = ['put', 'post'];
    return methodsWithPayloads.indexOf(method) !== -1;
  };

  const map = {
    getDataByPost: 'post',
  };

  const method = map[handle.method]
    ? map[handle.method]
    : handle.method.indexOf('get') !== -1
      ? 'get'
      : handle.method.indexOf('put') !== -1
        ? 'put'
        : 'post';

  const headersAndParams = handle.getHeadersAndParams(fixtures.cancelToken);

  const methodArguments = methodRequiresPayload(method)
    ? [handle.path, payload, headersAndParams]
    : [handle.path, headersAndParams];

  if (handle.cachable()) resolve(getState[name]);

  return axios[method](...methodArguments)
    .then(response => {
      defaultSuccessHandler(response, {resolve}, handle, flags, config);
    }).catch(error => {
      if (!ignoreResponseStatuses.includes(error.response.status)) {
        const params = headersAndParams.params;
        const headers = headersAndParams.headers;
        const method = handle.method + ': ' + handle.path;
        const trace = error.response.status + (' ' + JSON.stringify(get(error, 'response.statusText', ''))).trim() + ': ' + JSON.stringify(get(error, 'response.data', ''));
        const extra_data = JSON.stringify({params: params, payload: payload});
        logTrace(headers, 'ui_api_actions_failure', method, trace, extra_data);
      }
      defaultErrorHandler(error, {reject, resolve}, handle, fixtures);
    });
};

export function getItem (path, name, messages, params, next = () => {}, decorateItem = i => i, config) {
  const handle = new requestHandler('getItem', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const fixtures = {dispatch, getState, axios, config};
    return defaultAjaxCall(handle, {resolve, reject}, fixtures, {showWarnings: true});
  });
}

export function getData (path, name, messages, params, next = () => {}, config) {
  const handle = new requestHandler('getData', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const fixtures = {dispatch, getState, axios, stopSpinner, config};
    return defaultAjaxCall(handle, {resolve, reject}, fixtures, {showWarnings: true});
  });
}

export function putItem (path, payload, name, messages, params, next = () => {}, config) {
  const handle = new requestHandler('putItem', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const fixtures = {dispatch, getState, axios, payload, stopSpinner, config};
    return defaultAjaxCall(handle, {resolve, reject}, fixtures, {showWarnings: true});
  });
}

export function putData (path, payload, name, messages, params, next = () => {}, config) {
  const handle = new requestHandler('putData', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const fixtures = {dispatch, getState, axios, payload, config};
    return defaultAjaxCall(handle, {resolve, reject}, fixtures, {showWarnings: true});
  });
}

export function postItem (path, payload, name, messages, params, next = () => {}, config) {
  const handle = new requestHandler('postItem', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const fixtures = {dispatch, getState, axios, payload, stopSpinner, config};
    return defaultAjaxCall(handle, {resolve, reject}, fixtures, {showWarnings: true});
  });
}

export function postData (path, payload, name, messages, params, next = () => {}, config) {
  const handle = new requestHandler('postData', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const fixtures = {dispatch, getState, axios, payload, config};
    return defaultAjaxCall(handle, {resolve, reject}, fixtures, {showWarnings: true});
  });
}

export function getDataByPost (path, payload, name, messages, params, next = () => {}, cancelToken, config) {
  const handle = new requestHandler('getDataByPost', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const fixtures = {dispatch, getState, axios, payload, stopSpinner, config, cancelToken};
    return defaultAjaxCall(handle, {resolve, reject}, fixtures, {showWarnings: false});
  });
}


/***
 * Expressly passes paginate=1 in request as opposed to getData.
 * Include resolveRaw = true property in config to get the pagination data back.  Seems like that should be default
 * but is not as of 01/2019
 * @param path
 * @param name
 * @param messages
 * @param params
 * @param next
 * @param cancelToken
 * @param config
 * @returns {function(*=, *=): Promise}
 */
export function getPaginatedData (path, name, messages, params, next = () => {}, cancelToken, config) {
  const handle = new requestHandler('getPaginatedData', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    handle.redux(dispatch, getState).setRequestStart();
    const {headers} = handle.getHeadersAndParams();
    const configuration = {
      headers,
      params: Object.assign({}, params, {paginate: 1}),
      cancelToken: cancelToken || CancelTokens.getToken(path)
    };
    if (handle.cachable()) resolve(getState[name]);
    return axios.get(path, configuration)
      .then(response => {
        CancelTokens.remove(path);
        defaultSuccessHandler(response, {resolve}, handle, {showWarnings: true}, config);
      }).catch(error => {
        if (!ignoreResponseStatuses.includes(error.response.status)) {
          const method = handle.method + ': ' + handle.path;
          const trace = error.response.status + (' ' + JSON.stringify(get(error, 'response.statusText', ''))).trim() + ': ' + JSON.stringify(get(error, 'response.data', ''));
          const extra_data = JSON.stringify({params: params});
          logTrace(headers, 'ui_api_get_paginated_data_failure', method, trace, extra_data);
        }
        defaultErrorHandler(error, {reject, resolve}, handle, {dispatch, stopSpinner, axios});
      });
  });
}

export function getPaginatedDataByPost (path, name, messages, params, next = () => {}, cancelToken, config) {
  const handle = new requestHandler('getPaginatedData', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    handle.redux(dispatch, getState).setRequestStart();
    const {headers} = handle.getHeadersAndParams();
    const configuration = {
      headers,
      params: Object.assign({}, {paginate: 1}),
      cancelToken: cancelToken || CancelTokens.getToken(path)
    };
    if (handle.cachable()) resolve(getState[name]);
    return axios.post(path, params, configuration)
      .then(response => {
        CancelTokens.remove(path);
        defaultSuccessHandler(response, {resolve}, handle, {showWarnings: true}, config);
      }).catch(error => {
        if (!ignoreResponseStatuses.includes(error.response.status)) {
          const method = handle.method + ': ' + handle.path;
          const trace = error.response.status + (' ' + JSON.stringify(get(error, 'response.statusText', ''))).trim() + ': ' + JSON.stringify(get(error, 'response.data', ''));
          const extra_data = JSON.stringify({params: params});
          logTrace(headers, 'ui_api_get_paginated_data_by_post_failure', method, trace, extra_data);
        }
        defaultErrorHandler(error, {reject, resolve}, handle, {dispatch, stopSpinner, axios});
      });
  });
}

export function ensureGetUnpaginatedData (path, name, messages, params, next = () => {}, config) {
  return (dispatch, getState) => {
    const data = getState()[name];
    if (data && data.length) {
      next(data);
      return Promise.resolve(data);
    }
    return getUnpaginatedData(path, name, messages, params, next, config)(dispatch, getState);
  };
}

export function getUnpaginatedData (path, name, messages, params, next = () => {}, config) {
  const handle = new requestHandler('getUnpaginatedData', arguments);
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      handle.redux(dispatch, getState).setRequestStart();
      const {headers} = handle.getHeadersAndParams();
      const cancelToken = CancelTokens.getToken(path);
      const configuration = {headers, params: Object.assign({}, params, {paginate: 0}), cancelToken};
      if (handle.cachable()) resolve(getState[name]);
      return axios.get(path, configuration)
        .then(response => {
          CancelTokens.remove(path);
          defaultSuccessHandler(response, {resolve}, handle, {showWarnings: true}, config);
        }).catch(error => {
          if (!ignoreResponseStatuses.includes(error.response.status)) {
            const method = handle.method + ': ' + handle.path;
            const trace = error.response.status + (' ' + JSON.stringify(get(error, 'response.statusText', ''))).trim() + ': ' + JSON.stringify(get(error, 'response.data', ''));
            const extra_data = JSON.stringify({params: params});
            logTrace(headers, 'ui_api_get_paginated_data_failure', method, trace, extra_data);
          }
          defaultErrorHandler(error, {reject, resolve}, handle, {axios});
        });
    });
  };
}

//TODO: This is often called twice on load when used with TablePage.  Not sure why and is out of refactor scope.
export function getSearchData (path, name, messages, params, next = () => {}, cancelToken, config) {
  const handle = new requestHandler('getSearchData', arguments);
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      handle.redux(dispatch, getState).setRequestStart();
      const {headers} = handle.getHeadersAndParams();
      const configuration = {headers, params, cancelToken: cancelToken || CancelTokens.getToken(path)};
      if (handle.cachable()) resolve(getState[name]);
      return axios.get(path, configuration)
        .then(response => {
          CancelTokens.remove(path);
          defaultSuccessHandler(response, {resolve}, handle, {showWarnings: true}, config);
        }).catch(error => {
          if (!ignoreResponseStatuses.includes(error.response.status)) {
            const method = handle.method + ': ' + handle.path;
            const trace = error.response.status + (' ' + JSON.stringify(get(error, 'response.statusText', ''))).trim() + ': ' + JSON.stringify(get(error, 'response.data', ''));
            const extra_data = JSON.stringify({params: params});
            logTrace(headers, 'ui_api_get_search_data_failure', method, trace, extra_data);
          }
          defaultErrorHandler(error, {reject, resolve}, handle, {dispatch, stopSpinner, axios});
        });
    });
  };
}

export function getDataBatch (path, name, messages, params, next = () => {}, cancelToken = null, keyField = 'id', config) {
  const handle = new requestHandler('getDataBatchByPost', arguments);
  return (dispatch, getState) => {
    handle.redux(dispatch, getState).setRequestStart();
    const {headers} = handle.getHeadersAndParams();
    const configuration = {headers, params, cancelToken: cancelToken || CancelTokens.getToken(path)};
    return axios.get(path, configuration)
      .then(response => {
        CancelTokens.remove(path);
        handle.setResponse(response)
          .onSuccessMethod((handlerData) => {
            dispatch(getDataBatchSuccess(handle.response, name, getSuccessMessage(messages, handle.response.data), keyField));
          })
          .success()
          .runNext();
      }).catch(error => {
        if (axios.isCancel(error)) {
          dispatch(stopSpinner());
          return error;
        }
        if (!ignoreResponseStatuses.includes(error.response.status)) {
          const method = handle.method + ': ' + handle.path;
          const trace = error.response.status + (' ' + JSON.stringify(get(error, 'response.statusText', ''))).trim() + ': ' + JSON.stringify(get(error, 'response.data', ''));
          const extra_data = JSON.stringify({params: params});
          logTrace(headers, 'ui_api_get_data_batch_failure', method, trace, extra_data);
        }
        handle.setError(error);
        const failedMessage = getFailedMessage(messages, error);
        if (error.response) {
          if (error.response.headers && error.response.headers.authorization) {
            dispatch(setToken(error.response.headers.authorization));
          }
          dispatch(getDataBatchFailed(error.response.data, name, failedMessage));
        } else {
          dispatch(getDataBatchFailed({messages: [error]}, name, failedMessage));
        }
        return handle.showValidationErrors().failed();
      });
  };
}

export function getDataBatchByPost (path, payload, name, messages, params, next = () => {},
                                    cancelToken = null, keyField = 'id', config) {
  const handle = new requestHandler('getDataBatchByPost', arguments);
  return (dispatch, getState) => {
    handle.redux(dispatch, getState).setRequestStart();
    const {headers} = handle.getHeadersAndParams();
    const configuration = {headers, params, cancelToken: cancelToken || CancelTokens.getToken(path)};
    return axios.post(path, payload, configuration)
      .then(response => {
        CancelTokens.remove(path);
        handle.setResponse(response)
          .onSuccessMethod((handlerData) => {
            dispatch(getDataBatchSuccess(handle.response, name, getSuccessMessage(messages, handle.response.data), keyField));
          })
          .success()
          .runNext();
      }).catch(error => {
        if (axios.isCancel(error)) {
          dispatch(stopSpinner());
          return error;
        }
        if (!ignoreResponseStatuses.includes(error.response.status)) {
          const method = handle.method + ': ' + handle.path;
          const trace = error.response.status + (' ' + JSON.stringify(get(error, 'response.statusText', ''))).trim() + ': ' + JSON.stringify(get(error, 'response.data', ''));
          const extra_data = JSON.stringify({params: params});
          logTrace(headers, 'ui_api_get_data_batch_by_post_failure', method, trace, extra_data);
        }
        handle.setError(error);
        const failedMessage = getFailedMessage(messages, error);
        if (error.response) {
          if (error.response.headers && error.response.headers.authorization) {
            dispatch(setToken(error.response.headers.authorization));
          }
          dispatch(getDataBatchFailed(error.response.data, name, failedMessage));
        } else {
          dispatch(getDataBatchFailed({messages: [error]}, name, failedMessage));
        }
        return handle.showValidationErrors().failed();
      });
  };
}

// Tested success, 404, and errors - errors need refinement
// TODO: in cart page disable customers remove from queue so the order is deleted but the queue is not
// this results in a nested error the next time you try to cancel the order which will point to how to refine
// the error handling.
export function deleteItem (path, name, messages, params, next = () => {}, config) {
  const handle = new requestHandler('deleteItem', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    handle.redux(dispatch, getState).setRequestStart();
    const headersAndParams = handle.getHeadersAndParams();
    return axios.delete(path, headersAndParams)
      .then(response => {
        defaultSuccessHandler(response, {resolve}, handle, {showWarnings: false}, config);
      }).catch(error => {
        if (!ignoreResponseStatuses.includes(error.response.status)) {
          const headers = headersAndParams.headers;
          const method = handle.method + ': ' + handle.path;
          const trace = error.response.status + (' ' + JSON.stringify(get(error, 'response.statusText', ''))).trim() + ': ' + JSON.stringify(get(error, 'response.data', ''));
          const extra_data = JSON.stringify({params: params});
          logTrace(headers, 'ui_api_delete_item_failure', method, trace, extra_data);
        }
        defaultErrorHandler(error, {reject, resolve}, handle, {axios});
      });
  });
}

// Tested success, 404, invalid
export function deleteData (path, id, name, messages, params, next = () => {}, config) {
  const handle = new requestHandler('deleteData', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    handle.redux(dispatch, getState).setRequestStart();
    const {headers} = handle.getHeadersAndParams();
    const isArray = Array.isArray(id);
    const deletePath = isArray ? path : `${path.replace(/\/$/, '')}/${id}`;

    return axios.delete(deletePath, {headers, params, data: id})
      .then(response => {
        handle.setResponse(response)
          .onSuccessMethod((args) => {
            handle.dispatch(deleteDataSuccess({data: [id]}, args.name, getSuccessMessage(args.messages, args.response.data)));
          })
          .success()
          .runNext();
        resolve(handle.response.data);
      }).catch(error => {
        if (!ignoreResponseStatuses.includes(error.response.status)) {
          const method = handle.method + ': ' + handle.path;
          const trace = error.response.status + (' ' + JSON.stringify(get(error, 'response.statusText', ''))).trim() + ': ' + JSON.stringify(get(error, 'response.data', ''));
          const extra_data = JSON.stringify({params: params, payload: {data: [id]}});
          logTrace(headers, 'ui_api_delete_data_failure', method, trace, extra_data);
        }
        const failedMessage = getFailedMessage(messages, error);
        dispatch(deleteDataFailed(error.response ? error.response.data : {messages: [error]}, name, failedMessage));
        defaultErrorHandler(error, {reject, resolve}, handle, {axios});
      });
  });
}

// Tested success, forced 404, and real failure
export function getFile (path, fileName, messages, params, next = () => {}, config) {
  const handle = new requestHandler('getFile', arguments);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    handle.redux(dispatch, getState).setRequestStart();
    const {headers} = handle.getHeadersAndParams();
    return axios.get(path, {headers, params, responseType: 'arraybuffer'})
      .then(response => {
        handle.setResponse(response)
          .onSuccessMethod((handlerData) => {
            dispatch(getFileSuccess(getSuccessMessage(messages, response.data)));
            const file = new Blob([response.data], {type: 'application/octet-stream'});
            fileSaver.saveAs(file, fileName);
          })
          .success()
          .runNext();
        resolve();
      })
      .catch(error => {
        if (axios.isCancel(error)) resolve('Request canceled');
        if (!ignoreResponseStatuses.includes(error.response.status)) {
          const method = handle.method + ': ' + handle.path;
          const trace = error.response.status + (' ' + JSON.stringify(get(error, 'response.statusText', ''))).trim() + ': ' + JSON.stringify(get(error, 'response.data', ''));
          const extra_data = JSON.stringify({params: params, fileName: fileName});
          logTrace(headers, 'ui_api_get_file_failure', method, trace, extra_data);
        }
        handle.setError(error);
        dispatch(setToken(error.response.headers.authorization));
        dispatch(getFileFailed(getFailedMessage(messages, error)));
        reject(error);
        return Promise.reject(error);
      });
  });
}

//TODO: Add handling of messages returned directly from api-gateway - eg {messages: unable to load endpoint}
export const requestHandler = function (actionMethod, args) {
  this.method = actionMethod;
  this.actionMethods = {
    putItem: {
      arguments: ['path', 'payload', 'name', 'messages', 'params', 'next', 'config'],
      success: editItemSuccess,
      failure: editItemFailed,
    },
    postItem: {
      arguments: ['path', 'payload', 'name', 'messages', 'params', 'next', 'config'],
      success: addItemSuccess,
      failure: addItemFailed,
    },
    getData: {
      arguments: ['path', 'name', 'messages', 'params', 'next', 'config'],
      success: getDataSuccess,
      failure: getDataFailed,
    },
    getSearchData: {
      arguments: ['path', 'name', 'messages', 'params', 'next', 'cancelToken', 'config'],
      success: getDataSuccess,
      failure: getDataFailed
    },
    getUnpaginatedData: {
      arguments: ['path', 'name', 'messages', 'params', 'next', 'config'],
      success: getDataSuccess,
      failure: getDataFailed,
    },
    getPaginatedData: {
      arguments: ['path', 'name', 'messages', 'params', 'next', 'cancelToken', 'config'],
      success: getDataSuccess,
      failure: getDataFailed,
    },
    getItem: {
      arguments: ['path', 'name', 'messages', 'params', 'next', 'decorateItem', 'config'],
      success: getItemSuccess,
      failure: getItemFailed
    },
    postData: {
      arguments: ['path', 'payload', 'name', 'messages', 'params', 'next', 'config'],
      success: addDataSuccess,
      failure: addDataFailed
    },
    getDataByPost: {
      arguments: ['path', 'payload', 'name', 'messages', 'params', 'next', 'cancelToken', 'config'],
      success: getDataSuccess,
      failure: getDataFailed
    },
    putData: {
      arguments: ['path', 'payload', 'name', 'messages', 'params', 'next', 'config'],
      success: editDataSuccess,
      failure: editDataFailed
    },
    deleteItem: {
      arguments: ['path', 'name', 'messages', 'params', 'next', 'config'],
      success: deleteItemSuccess,
      failure: deleteItemFailed
    },
    deleteData: {
      arguments: ['path', 'id', 'name', 'messages', 'params', 'next', 'config'],
      success: deleteDataSuccess,
      failure: deleteDataFailed,
    },
    getFile: {
      arguments: ['path', 'fileName', 'message', 'params', 'next', 'config'],
      success: getFileSuccess,
      failure: getFileFailed
    },
    getDataBatchByPost: {
      arguments: ['path', 'payload', 'name', 'messages', 'params', 'next',
        'cancelToken', 'keyField', 'config'],
      success: getDataBatchSuccess,
      failure: getDataBatchFailed
    },
    getDataBatch: {
      arguments: ['path', 'name', 'messages', 'params', 'next', 'cancelToken', 'keyField', 'config'],
      success: getDataBatchSuccess,
      failure: getDataBatchFailed,
    },
  };

  this.fatalStatus = {
    [404]: '404 Not Found Error',
    [422]: '422 Validation Error',
    [500]: '500 Service Error',
    [502]: '502 Service Error',
    [503]: '503 Service Unavailable',
    [504]: 'Gateway timeout',
  };

  this.action = this.actionMethods[actionMethod];

  this.config = false;
  this.hash = false;
  this.path = false;
  this.payload = false;
  this.name = false;
  this.messages = false;
  this.params = false;
  this.next = false;
  this.decorateItem = false;
  this.errorAlreadyDisplayed = false; // Suppresses failed message when handled by validation error display.
  this.getState = false;
  this.dispatch = false;

  this.response = false;
  this.error = false;

  this.cancelRequest = false;
  this.isComplete = false;

  this.timedRequestNow = false;

  this.debug = false;

  if (args !== undefined) this.arguments(args);

  if (this.debug) console.log(actionMethod, this.name, 'INIT'); //eslint-disable-line no-console

};

// Override default onSuccess method
requestHandler.prototype.onSuccessMethod = function (method) {
  this.action.altSuccess = method;
  return this;
};

// Override default onFailure method
requestHandler.onFailureMethod = function (method) {
  this.action.altFailure = method;
};

// parse arguments from initial call into object
requestHandler.prototype.arguments = function (args) {
  const defaultToEmptyObject = ['messages', 'payload', 'params'];
  this.action.arguments.forEach((argument, index) => {
    this[argument] = (defaultToEmptyObject.indexOf(argument) !== -1 && args[index] === null || args[index] === undefined) ? {} : args[index];
  });
  if (this.config === null || this.config === undefined) {
    this.config = false;
  }
  return this;
};

requestHandler.prototype.redux = function (dispatch, getState) {
  this.dispatch = dispatch;
  this.getState = getState;
  return this;
};

// Return a headers and params object
requestHandler.prototype.getHeadersAndParams = function (cancelToken) {
  const request = {};
  let requestHeaders = {};
  let user_id, facility_id, organization_id = '';
  const uuid = quickHash(v4());
  const params = (this.params === undefined) ? {} : this.params;
  if (this.getState) {
    const {headers, user, facility} = this.getState();
    requestHeaders = headers;
    if (user && user.id) {
      user_id = user.id;
    }
    if (facility && facility.id) {
      organization_id = facility.organizationId;
      facility_id = facility.id;
    }
  }
  requestHeaders['x-mjf-tracking'] = `${organization_id}-${facility_id}-${user_id}-${uuid}`;

  const serializeNestedParams = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        obj[key] = JSON.stringify(obj[key]);
      }
    }
    return obj;
  };

  request.headers = requestHeaders;
  request.params = serializeNestedParams(params);
  if (cancelToken) {
    request.cancelToken = cancelToken;
  }

  return this.updateRequestForTimeout(request);
};

requestHandler.prototype.setRequestStart = function () {
  this.timedRequestNow = Date.now();
  this.dispatch(batchActions([startRequest(this.name, this.timedRequestNow), startSpinner()]));
  this.handleConfiguredTimeouts();
  return this;
};

requestHandler.prototype.cachable = function (resolve) {
  if (this.name != null) {
    const organization = this.getState().facility.organizationId;
    const version = this.getState().system.currentVersion;
    const request = {
      path: this.path,
      params: this.params,
      payload: this.payload,
      organization,
      version
    };
    this.hash = quickHash(request);
    if (cachableNames.indexOf(this.name) != -1 || (this.config && this.config.forceCache)) {
      const meta = this.getState()['meta'];
      const cache = this.config && this.config.cache !== undefined ? this.config.cache : true;
      if (meta && meta[this.name] && meta[this.name].hash) {
        if (this.hash === meta[this.name].hash && cache) {
          return true;
        }
      }
    }
  }
  return false;
};

requestHandler.prototype.setResponse = function (response, runActions = true) {
  this.isComplete = true;
  if (this.debug) console.log(response, this.name, this.method, 'response, name, method in setResponse');  //eslint-disable-line no-console
  const isUnPaginatedCall = () => (this.action === this.actionMethods.getUnpaginatedData);
  const {data} = response;
  const debounce = this.config && this.config.debounce || false;
  const newPayload = {
    data,
    headers: response.headers,
    startTime: this.timedRequestNow,
    stopTime: Date.now(),
    debounce
  };
  if (this.hash) {
    newPayload.hash = this.hash;
  }
  if (data.results) { //handle search result data
    newPayload.data = data.results;
    newPayload.pagination = {total: data.found, from: data.start};
    newPayload.facets = data.facets;
  } else if (data.pagination || (data.data && data.per_page && !isUnPaginatedCall()) || (data.response && typeof data.response !== 'string')) { // handle paginated data (catalog returns response instead of data)
    newPayload.data = (data.data && data.per_page) ? data.data : data.response ? data.response : {};
    newPayload.facets = data.facets;
    newPayload.pagination = data.pagination
      ? {total: data.pagination.total, from: data.pagination.from} // catalog returns this format
      : {total: data.total, from: data.from}; // unsure if this is in use
  }
  if (this.decorateItem && typeof this.decorateItem === 'function') { //handle data with a decorate functon
    newPayload.data = this.decorateItem(data);
  }
  this.response = newPayload;
  if (runActions) this.runDefaultActions();
  return this;
};

requestHandler.prototype.setError = function (error, runActions = true) {
  this.isComplete = true;
  if (this.debug) console.log(this.name, this.method, 'name, method in setError');  //eslint-disable-line no-console
  this.error = error;
  this.response = this.error.response;
  if (runActions) this.runDefaultActions();
  return this;
};

requestHandler.prototype.hasWarnings = function () {
  return this.response.warnings !== undefined || this.response.data.warnings !== undefined;
};

requestHandler.prototype.showWarnings = function () {
  if (!this.hasWarnings()) return this;
  if (this.config && this.config.suppressWarnings) return this;
  const warnings = this.response.warnings ? this.response.warnings : this.response.data.warnings;
  if (Array.isArray(warnings)) {
    warnings.forEach((warning, index) => {
      this.dispatch(addMessage(messageTypes.warning, warning, true));
    });
    return this;
  }
  Object.keys(warnings).forEach((key) => {
    const messages = warnings[key];
    if (Array.isArray(messages)) {
      messages.forEach((message) => {
        this.dispatch(addMessage(messageTypes.warning, message, true));
      });
    }
  });
  return this;
};

requestHandler.prototype.hasValidationErrors = function () {
  if (this.error.response === undefined) return false;
  return this.error.response.data && this.error.response.data.errors && this.error.response.data.errors.VALIDATION;
};

requestHandler.prototype.showValidationErrors = function () {
  if (this.hasErrorHandler() && !this.showBEValidationMessages()) return this;
  // NOTE: only validation logic (errors.VALIDATION) should to be here
  // all other error check should be done/moved to the functions 'failed' and 'runCustomErrorHandler'
  if (!this.hasValidationErrors()) return this;
  let validationErrors = this.error.response.data.errors.VALIDATION;
  if (typeof validationErrors === 'object') {
    validationErrors = getErrorsArrayFromObject(validationErrors);
  }
  if (!Array.isArray(validationErrors)) return this;
  validationErrors.forEach((error) => {
    // no need to translate system errors from backend, that's why we need to pass 'true'
    this.dispatch(addMessage(messageTypes.error, error, true));
  });
  // There is a validation trap in reducers too, for the moment left it there
  delete (this.error.response.data.errors.VALIDATION); // This might be able to be removed... in simple testing made no diff
  this.errorAlreadyDisplayed = true;
  delete (this.error.response.status); // Prevent validation errors that have messages from being run again by fatal check
  return this;

};

requestHandler.prototype.runCustomErrorHandler = function () {
  if (this.hasErrorHandler()) {
    const result = this.getErrorHandler()(this.error);
    if (result && result.message) {
      //TODO: Decide... Should this prevent downstream errors?  Good example is starting order from queue when limit exceeded
      //this.dispatch(addMessage(messageTypes.error, I18n.t(result.message, result), true));
      //return this;
      // So... to prevent double messages in these cases we return the preparsed value and handle that case downstream.
      // leaving this here for reference as these error messages are a bit convoluted and could use more work.
      return !result.translate ? result.message : I18n.t(result.message, result);
    }
  }
  return false;

};

requestHandler.prototype.getAllVars = function () {
  const vars = {};
  const exclude = ['actionMethods'];
  for (const prop in this) {
    if (this.hasOwnProperty(prop)) {
      if (typeof this[prop] !== 'function' && exclude.indexOf(prop) === -1) {
        vars[prop] = this[prop];
      }
    }
  }
  if (this.debug) console.log(vars, 'vars');  //eslint-disable-line no-console
  return vars;
};

requestHandler.prototype.failed = function (reject, alreadyRejected = false) {

  const doReject = (error) => {
    return alreadyRejected
      ? false
      : typeof reject === 'function'
        ? reject(error)
        : Promise.reject(error);
  };

  // eg. showWarnings displayed errors so lets not do any more messages.
  if (this.errorAlreadyDisplayed) {
    return doReject(this.error);
  }

  //handle case where the errorHandler was passed in config probably along with a timeout
  if (get(this.config, 'errorHandler.action', false)) {
    const action = get(this.config, 'errorHandler.action', false);
    const message = get(this.config, 'errorHandler.message', false);
    if (action && message) {
      action({message, time: new Date().getTime()});
    }
    if (action && !message) {
      action();
    }
    doReject(this.error);
  }

  const errorHandlerMessage = this.runCustomErrorHandler();
  const failedMessage = (errorHandlerMessage) ? errorHandlerMessage : getFailedMessage(this.messages, this.error);
  if (failedMessage === 'suppress') {
    return doReject(this.error);
  }

  if (!this.config.silenceErrors) {
    if (this.action.altFailure !== undefined) {
      this.action.altFailure();
      return this;
    }
    if (!this.error.response) {
      this.dispatch(this.action.failure({messages: [this.error]}, this.name, failedMessage));
    } else {
      if (this.isFatalFailure()) {
        this.showFatalFailure(failedMessage);
      } else {
        this.dispatch(this.action.failure(this.error.response.data, this.name, failedMessage));
      }
    }
  }
  return doReject(this.error);
};

requestHandler.prototype.getStringErrorMessage = function () {
  let stringMessage = false;
  if (this.messages.failed !== undefined) {
    if (typeof this.messages.failed !== 'function') {
      stringMessage = this.messages.failed;
    }
  }
  return stringMessage;
};

requestHandler.prototype.isFatalFailure = function () {
  const hasErrorHandler = this.hasErrorHandler();
  return this.fatalStatus[this.error.response.status] !== undefined && !hasErrorHandler;
};

//TODO: Calling the action with a 404 error or 502 results in an axios error in createError which can mess up things downstream
// Have seen this a few times in testing but never knew what exactly was causing it
requestHandler.prototype.showFatalFailure = function (errorMessage) {
  if (errorMessage === undefined) {
    const backend_message = get(this.error, 'response.data.errors.MESSAGE');

    const customMessageKey = this.getStringErrorMessage(); // Get error message from API request
    errorMessage = (customMessageKey)
      ? I18n.t(customMessageKey)
      : backend_message
        ? backend_message
        : get(this.response, 'status') === 504
          ? get(this.response, 'statusText')
          : I18n.t('common.error.fatalFailure');
  } else {
    errorMessage = I18n.t(errorMessage);
  }

  // // Add on TRACE_ID if available
  // let trace_id = get(this.error, 'response.data.errors.TRACE_ID');
  // if (!trace_id) {
  //   trace_id = get(this.error, 'response.headers.x-datadog-trace-id');
  // }
  // if (trace_id) {
  //   errorMessage += ' - ' + I18n.t('common.error.fatalFailureTraceId', {trace_id});
  // }

  const data = (this.error.response !== undefined)
    ? (this.error.response.data !== undefined)
      ? this.error.response.data
      : []
    : undefined;
  if (errorMessage && (typeof errorMessage === 'string' || errorMessage instanceof String)) {
    this.dispatch(this.action.failure(data, this.name, errorMessage));
  }
};

requestHandler.prototype.getFromState = function () {
  if (!this.debug) return this;
  setTimeout(() => {
    const data = this.getState()[this.name];
    console.log(data, this.name, 'in STATE');  //eslint-disable-line no-console
  }, 1000);
};

requestHandler.prototype.success = function (runNext = false) {
  if (this.config && this.config.errorHandler && this.config.errorHandler.clearOnSuccess) {
    if (typeof this.config.errorHandler.action === 'function') {
      this.dispatch(stopSpinner()); // Force stop spinner
      this.config.errorHandler.action({});
    }
  }
  if (!this.response || this.response === undefined) return this; // wondering if this should be trapped
  if (this.debug) console.log(this.name, this.method, this.response.data, 'name, method, data in success');  //eslint-disable-line no-console
  if (this.debug) this.getFromState();
  if (this.action.altSuccess !== undefined) {
    this.action.altSuccess(this.getAllVars());
    return this;
  }
  if (this.name != null) {
    this.dispatch(this.action.success(Object.assign({}, this.response), this.name, getSuccessMessage(this.messages, Object.assign({}, this.response.data))));
  } else {
    this.dispatch(stopSpinner());
  }
  if (runNext) return this.runNext();
  return this;
};

requestHandler.prototype.timedRequestOk = function () {
  if (!this.timedRequestNow) return true;
  const request = this.getState().requests[this.name];
  return (!request || !request.stop || request.start <= this.timedRequestNow);
};

requestHandler.prototype.timedRequestComplete = function () {
  this.dispatch(completeRequest(this.name, this.timedRequestNow, Date.now()));
  return this;
};

requestHandler.prototype.runNext = function () {
  const data = (this.response === undefined) ? {} : this.response.data;
  if (typeof this.next !== 'function') return this;
  this.next(data);
  return this;
};

requestHandler.prototype.hasErrorHandler = function () {
  return this.messages.failedHandler !== undefined || (typeof this.messages.failed === 'function');
};

requestHandler.prototype.showBEValidationMessages = function () {
  return this.messages.showBEValidationMessages;
};

requestHandler.prototype.getErrorHandler = function () {
  return !this.hasErrorHandler()
    ? () => false
    : (typeof this.messages.failed === 'function')
      ? this.messages.failed
      : this.messages.failedHandler;
};

requestHandler.prototype.runDefaultActions = function () {
  if (!this.response || this.response === undefined) return this; // wondering if this should be trapped
  if (this.debug) console.log(this.response, this.name, 'response, name in runDefaultActions');  //eslint-disable-line no-console
  const version = this.response.headers['x-mjf-version'];
  const authorization = this.response.headers['authorization'];
  const actions = [];
  if (version && this.getState().system.currentVersion != version) {
    actions.push(setCurrentVersion(version));
  }
  if (authorization && this.getState.authorization != authorization) {
    actions.push(setToken(authorization));
  }
  if (actions.length) {
    this.dispatch(batchActions(actions));
  }
  return this;
};

/******************************************************
 * BEGIN configured timeouts and error handler
 ******************************************************/

/**
 * Config object passed in may have a timeout property which defines the milliseconds for a timeout
 * and an action to take on timeout.
 */
requestHandler.prototype.handleConfiguredTimeouts = function () {
  const timeout = get(this.config, 'timeout', false);
  if (!timeout) {
    return false;
  }
  // Create a holder for our cancelRequst
  this.config.cancelTokenObject = {
    cancelToken: new axios.CancelToken((c) => {
      this.cancelRequest = c;
    })
  };

  // Set up the timer which is a simple timeout vs interval that need be cleared.
  const timeoutInMs = get(this.config, 'timeout.ms', 0);
  const action = get(this.config, 'timeout.action', false);
  const message = get(this.config, 'timeout.message', false);
  const handler = this;
  setTimeout(function () { //eslint-disable-line
    if (handler.isComplete) {
      return false;
    }
    if (handler.cancelRequest) {
      handler.cancelRequest();
      if (typeof action === 'function') {
        action({message, time: new Date().getTime()});
      }
    }
  }, timeoutInMs);
  return true;
};

/**
 * Configured timeouts can cancel a request; this makes cancellation possible.
 * @param request
 * @returns {*}
 */
requestHandler.prototype.updateRequestForTimeout = function (request) {
  const cancelTokenObject = get(this.config, 'cancelTokenObject', false);
  if (cancelTokenObject) {
    request.cancelToken = cancelTokenObject.cancelToken;
  }
  return request;
};

/**************************************************
 * END configured timeouts and error handler
 **************************************************/

export function getRequestHandler () {
  return new requestHandler('getData');
}

export const getErrorsArrayFromObject = (validationErrors) => {
  return Object.values(validationErrors).reduce(
    (acc, current) => {
      let errors = [];
      if (typeof current === 'string') {
        errors = [current];
      } else if (Array.isArray(current)) {
        errors = current.filter(Boolean);
      }
      return acc.concat(errors);
    }
    , []);
};
